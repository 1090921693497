import Api from '@/utils/axios/Api';
const Http = Api.getInstance();



export const ClassificationCreate = (payload:Object) => {
    return new Promise((resolve, reject) => {
        Http.post('classifications' , payload)
        .then((d) => {
            resolve(d)
        }).catch(e => {
            reject(e)
        })
    })
}



export const ClassificationUpdate = (id :number , payload:Object) => {
    return new Promise((resolve, reject) => {
        Http.put(`classifications/${id}` , payload)
        .then((d) => {
            resolve(d)
        }).catch(e => {
            reject(e)
        })
    })
}

export const ClassificationFind = (id: number) => {
    return new Promise((resolve, reject) => {
        Http.get(`classifications/${id}`)
        .then((d) => {
            resolve(d)
        }).catch(e => {
            reject(e)
        })
    })
}



export const ClassificationDelete = (id: number) => {
    return new Promise((resolve, reject) => {
        Http.delete(`classifications/${id}`)
        .then((d) => {
            resolve(d)
        }).catch(e => {
            reject(e)
        })
    })
}

